<template>
  <app-card v-if="!isLoading && tableData.length">
    <TableTitle
      v-if="title"
      :title="title"
      editable
      @edit-title="editTitle = true"
      @add="addModal = true"
    />

    <AppFilter
      v-if="!isLoading"
      v-model="filter"
      type="book"
    />

    <AppTable
      v-if="!isLoading && items.length"
      editable
      :data="currentPageItems"
      :fields="{
        place: 'Место',
        name: 'Название',
        genre: 'Жанр',
        points: 'Оценка',
        scale: 'Шкала об-cти'
      }"
      @edit="editElement"
      @selected="openElement"
      @remove="removeElement"
    />

    <AppPagination
      v-if="!isLoading && items.length"
      v-model="page"
      :data="items"
      :items-count="PAGE_ITEMS_COUNT"
    />

    <EditModal
      :visible="editModal"
      @close="editModal = false"
      @action="changeElementData"
      :item="selectedEditItem"
      type="book"
    />

    <AddModal
      :visible="addModal"
      @close="addModal = false"
      @action="addElement"
      type="book"
    />

    <app-modal
      title="Заголовок категории"
      :visible="editTitle"
      action
      @action="changeTitle('books', $refs.titleInput.value)"
      @close="editTitle = false"
    >
      <input
        type="text"
        :value="title"
        placeholder="Введите заголовок"
        style="text-align: center"
        ref="titleInput"
      >
    </app-modal>

    <AppEmpty
      v-if="!items.length"
      title="Ничего нет..."
    />
  </app-card>
</template>

<script>
import { useAdminTable } from '../use/admin-table'
import { useRouter } from 'vue-router'
import { useFilterAndPagination } from '../use/filter-and-pagination'
import AppTable from '../components/ui/AppTable'
import EditModal from '../components/ui/EditModal'
import AppModal from '../components/ui/AppModal'
import AddModal from '../components/ui/AddModal'
import AppPagination from '../components/ui/AppPagination'
import TableTitle from '../components/ui/TableTitle'
import AppFilter from '../components/ui/AppFilter'
import AppEmpty from '../components/ui/AppEmpty'
import AppCard from '../components/ui/AppCard'

export default {
  name: 'AdminBooks',
  components: {
    AppTable,
    EditModal,
    AppModal,
    AddModal,
    AppPagination,
    TableTitle,
    AppFilter,
    AppEmpty,
    AppCard
  },
  setup () {
    const router = useRouter()
    return {
      openElement: ({ id }) => {
        router.push({ name: 'Book', params: { id } })
      },
      ...useAdminTable('books'),
      ...useFilterAndPagination('books', true)
    }
  }
}
</script>
